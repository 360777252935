/* Common styles for td elements */
.input-container {
  display: flex;
  align-items: center;
}

.input-container label {
  margin-right: 10px;
}

.view-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


@media only screen and (max-width: 600px) {
  .view-button-container {
    width: 100%;
  }
}
