.employeeview-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  text-align: center;
}

.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tabs button {
  background-color: #4301d2;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tabs button.active {
  background-color: #5388a7;
}

.tab-content {
  border: 1px solid #ddd;
  padding: 20px;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
}

.logout-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f44336;
  color: white;
  padding: 20px;
  border-radius: 5px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.modal-content .close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.table-container {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin-top: 20px;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}

th {
  background-color: #f2f2f2;
}

.time-sheet-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropdowns {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

label,
select {
  margin-right: 10px;
}.logout-btn {
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  
}

.welcome-message {
  margin-right: 10px;
}
