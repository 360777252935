/* AddEmployee.css */

.container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 20px;
  }
  
  .heading {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .label {
    margin-bottom: 5px;
  }
  
  .input {
    padding: 10px;
    font-size: 16px;
    border-radius: 3px;
    border: 1px solid #090909;
  }
  
  .button {
    background-color: #8e5fe5;
    color: white;
    padding: 10px;
    font-size: 16px;
    border-radius: 3px;
    cursor: pointer;
  }


.form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.label {
  margin-bottom: 5px;
  white-space: nowrap;
}

.input, .textarea {
  flex: 2;
  padding: 10px;
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid #090909;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.button {
  flex: 1;
  background-color: #7061c7;
  color: white;
  padding: 10px;
  font-size: 16px;
  border-radius: 3px;
  cursor: pointer;
  border: none;
}

.button:hover {
  background-color: #43287d;
}

.back-button {
  margin-right: 10px;
}
