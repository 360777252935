/* EditEmployee.css */

.edit-employee-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .edit-employee-container h2 {
    text-align: center;
    color: #333;
  }
  
  .edit-employee-container label {
    display: block;
    margin: 10px 0;
  }
  
  .edit-employee-container input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    box-sizing: border-box;
  }
  
  .edit-employee-container button {
    background-color: #4d57c6;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .edit-employee-container button:hover {
    background-color: #5e2cdc;
  }
  .edit-employee-container textarea {
    width: 100%;
  }