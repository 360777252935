.add-timesheet-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.add-timesheet-form {
  display: flex;
  flex-direction: column;
}

.add-timesheet-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.add-timesheet-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.back-button,
.add-timesheet-button {
  padding: 10px;
  background-color: #0800ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.back-button:hover,
.add-timesheet-button:hover {
  background-color: #0056b3;
}

.add-timesheet-button {
  margin-left: 10px;
}
