.managerview-container {
  text-align: center;
  width: 95%;
  margin: auto;
  padding: 20px;
  position: relative;
}

.logout-btn {
  position: absolute;
  top: 10px;
  right: 20px;
}

.tabs-container {
  width: calc(100% - 40px); 
  margin: auto; 
}

.tabs {
  display: flex;
  gap: 0px; 
  justify-content: space-between; 
}

button {
  flex: 1;
  padding: 10px;
  cursor: pointer;
  border: none;
  background-color: #665fcf;
  color: white;
}

button.active {
  background-color: #92928c;
}

.tab-content {
  margin-top: 20px;
}

/* Modal styles */
.modal {
  display: none; 
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0, 0, 0, 0.4); 
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; 
  padding: 20px;
  border: 1px solid #888;
  width: 80%; 
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


.logout-btn {
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  
}

.welcome-message {
  margin-right: 10px;
}