/* MyData.css */

.employee-info-container {
  max-width: 500px;
  margin: 0 auto;
}

.error-message {
  color: red;
}

.form-group {
  margin-bottom: 15px;
  display: flex; 
  align-items: center; 
}

label {
  flex: 1; 
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  flex: 2; 
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.back-button, .edit-button {
  padding: 10px 20px;
  background-color: #742bcc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-button:hover, .edit-button:hover {
  background-color: #6939c9;
}
.non-editable {
  background-color: #f2f2f2; /* Light grey background */
}
/* MyData.css */

.success-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
